import { PropsWithChildren } from "react";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";

import Image from "@components/atoms/Image";
import { getVideoType } from "@utils/getVideoType";

import { HeroProps } from "./types";
import * as Styled from "./styles";
import { useVideoAutoplay } from "./hooks";

const Hero: React.FC<PropsWithChildren<HeroProps>> = ({
  title,
  backgroundImage,
  desktopBackgroundImage,
  backgroundVideo,
  videoPoster,
  children
}) => {
  const videoRef = useVideoAutoplay();
  const videoType = backgroundVideo
    ? getVideoType(backgroundVideo)
    : "";

  return (
    <Styled.Wrapper
      flexDirection="column"
      justifyContent="flex-end"
      p={["4rem 0", "4rem 0 5.5rem", "4rem 0 7rem"]}
      minHeight={[
        "calc(100vh - 3.75rem)",
        "100vh",
        "calc(100vh - 2.5rem)"
      ]}
      aria-label="Hero"
    >
      {backgroundVideo && (
        <Styled.Video
          loop
          playsInline
          muted
          poster={videoPoster && `${videoPoster}/m/`}
          ref={videoRef}
          preload="none"
        >
          <source src={backgroundVideo} type={`video/${videoType}`} />
        </Styled.Video>
      )}
      {(backgroundImage || desktopBackgroundImage) && (
        <Styled.Media>
          {backgroundImage && (
            <Styled.BackgroundImage>
              <Image
                {...backgroundImage}
                priority
                style={{
                  objectFit: "cover"
                }}
                sizes="100vw"
                quality={50}
              />
            </Styled.BackgroundImage>
          )}
          {!backgroundVideo && desktopBackgroundImage && (
            <Styled.DesktopBackgroundImage>
              <Styled.DesktopImage
                {...desktopBackgroundImage}
                style={{
                  objectFit: "cover"
                }}
                fill
                sizes="100vw"
                priority
              />
            </Styled.DesktopBackgroundImage>
          )}
        </Styled.Media>
      )}
      <Styled.Content>
        <Styled.Title>
          {typeof title === "string" ? (
            <Typography variant="heading1">{title}</Typography>
          ) : (
            title
          )}
        </Styled.Title>
        <Styled.Actions>{children}</Styled.Actions>
      </Styled.Content>
    </Styled.Wrapper>
  );
};

export default Hero;
