import { useEffect, useState } from "react";
import { useTheme } from "styled-components";

import { convertRemToPixels } from "@utils/misc";

const useMedia = (): boolean => {
  const { breakpoints } = useTheme();
  const [width, setWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 1920
  );

  useEffect(() => {
    function handleWindowSizeChange() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleWindowSizeChange, {
      passive: true
    });

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isTablet = width >= convertRemToPixels(breakpoints[1]);

  return isTablet;
};

export default useMedia;
