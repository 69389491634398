import styled from "styled-components";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";
import { FlexBox } from "@pagepro-monorepo/ui/lib/components/styles/Grid";
import shouldForwardProp from "@pagepro-monorepo/ui/lib/utils/shouldForwardProp";

import Image from "@components/atoms/Image";

export const Wrapper = styled(FlexBox)`
  min-height: calc(100vh - 3.75rem);

  @media ${media.mobile} {
    min-height: 100vh;
  }

  @media ${media.tablet} {
    min-height: calc(100vh - 2.5rem);
  }

  @media ${media.desktopXL} {
    padding-top: 7rem;
    justify-content: center;
  }
`;

export const Video = styled.video`
  display: none;

  @media ${media.tablet} {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(32%, 0);
    max-height: 100%;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Media = styled.div`
  position: absolute;
  inset: 0;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const BackgroundImage = styled.figure`
  width: 100%;
  height: 100%;

  @media ${media.tablet} {
    display: none;
  }
`;

export const DesktopBackgroundImage = styled.figure`
  display: none;
  width: 100%;
  height: 100%;

  @media ${media.tablet} {
    display: block;
  }
`;

export const DesktopImage = styled(Image)`
  transform: translate(32%, 0);
`;

export const Content = styled.div`
  z-index: 1;
`;

export const Title = styled.div`
  margin-bottom: 2.625rem;

  @media ${media.mobile} {
    margin-bottom: 4.875rem;
  }

  @media ${media.tablet} {
    margin-bottom: 5.5rem;
  }
`;

export const Actions = styled(FlexBox).withConfig({
  shouldForwardProp
})`
  gap: 0.5rem;

  @media ${media.mobile} {
    gap: 1.25rem;
  }

  @media ${media.tablet} {
    gap: 0.625rem;
  }
`;
