import React from "react";
import { storyblokEditable } from "@storyblok/react";

import ButtonLink from "@components/atoms/ButtonLink";
import Hero from "@components/organisms/Hero";
import RichText from "@components/atoms/RichText";
import { HeroProps } from "@components/organisms/Hero/types";
import { getImage } from "@utils/getImage";

import { HeroStoryblokProps } from "./types";

const HeroStoryblok: React.FC<HeroStoryblokProps> = ({ blok }) => {
  const {
    _uid: key,
    title,
    backgroundImage,
    desktopBackgroundImage,
    backgroundVideo,
    videoPoster,
    cta
  } = blok;

  const item: HeroProps = {
    title: <RichText content={title[0]?.text} />,
    backgroundImage: backgroundImage && getImage(backgroundImage),
    desktopBackgroundImage:
      desktopBackgroundImage && getImage(desktopBackgroundImage),
    backgroundVideo: backgroundVideo?.filename,
    videoPoster: videoPoster?.filename
  };

  return (
    <Hero {...{ key, ...item, ...storyblokEditable(blok) }}>
      {cta?.length &&
        cta?.map((button) => {
          const {
            _uid: uid,
            link: {
              url,
              cached_url: cachedUrl,
              rel,
              target,
              story: { full_slug: fullSlug = "" } = {}
            } = {},
            mode: $mode,
            size,
            label
          } = button;
          const newestUrl = fullSlug || cachedUrl;
          const href = url || newestUrl;

          return (
            <ButtonLink
              key={uid}
              {...{ href, $mode, size, rel, target }}
            >
              {label}
            </ButtonLink>
          );
        })}
    </Hero>
  );
};

export default HeroStoryblok;
