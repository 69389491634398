import { useRef, useLayoutEffect } from "react";

import useMedia from "@hooks/useMedia";

import { UseVideoAutoplay } from "./types";

export const useVideoAutoplay: UseVideoAutoplay = () => {
  const isTablet = useMedia();
  const videoRef = useRef<HTMLVideoElement>(null);

  useLayoutEffect(() => {
    if (!isTablet) {
      return;
    }

    videoRef.current?.play();
  }, [isTablet]);

  return videoRef;
};
